// https://day.js.org/docs/en/plugin/relative-time
import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime)

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(ts: number, ...args: unknown[]): unknown {
    return dayjs().to(dayjs(ts));
  }

}
