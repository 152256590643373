<!-- <div class="container"> -->



<div class="card mb-2 shadow-sm border-0 rounded-0">
    <div class="card-body">

        <div class="d-flex flex-row flex-wrap mb-3">
            <div class="p-0 border-0">
                <img src="//placehold.it/50" alt="" class="rounded-circle">
            </div>
            <div class="px-2">
                <div class="font-weight-bold">{{ data.name }}</div>
                <div class="small font-weight-light">{{dateStr}}</div>
            </div>
        </div>

        <!-- <div class="">Daily Activity</div> -->
        <!-- <h4 class="card-subtitle mb-2 text-muted">Daily Activity</h4> -->

        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="card-subtitle mb-2 text-muted">Steps</div>
                    <h3>200</h3>
                </div>
                <div class="col">
                    <div class="card-subtitle mb-2 text-muted">Active time</div>
                    <h3>1h 30min</h3>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="card-subtitle mb-2 text-muted">Sedentary time</div>
                    <h3>10h 45min</h3>
                </div>
                <!-- <div class="col">
                    <div class="card-subtitle mb-2 text-muted">Intensity Minutes</div>
                    <h3></h3>
                </div> -->
                <div class="col">
                    <div class="card-subtitle mb-2 text-muted">Daily activity score</div>
                    <h3>{{data.activeScore}} / 100</h3>
                </div>
            </div>
        </div>

    </div>
    <div class="card-footer text-muted p-1 border-top-0">

        <div class="d-flex justify-content-around">
            <a class="nav-link">
                <!-- Share on feed -->
                <fa-icon [icon]="faShareSquare" class="fa-lg text-muted"></fa-icon>
            </a>
            <a class="nav-link">
                <!-- Detailed breakdown -->
                <fa-icon [icon]="faChartLine" class="fa-lg text-dark"></fa-icon>
            </a>
            <a class="nav-link">
                <!-- Share -->
                <fa-icon [icon]="faShareAlt" class="fa-lg text-dark"></fa-icon>
            </a>
        </div>

        <!-- <ul class="list-group list-group-horizontal">
            <li class="list-group-item flex-fill">
                <a class="nav-link">
                    <fa-icon [icon]="faShareSquare" class="fa-lg text-dark"></fa-icon>
                </a>
            </li>
            <li class="list-group-item flex-fill">
                <a class="nav-link">
                    <fa-icon [icon]="faChartLine" class="fa-lg text-dark"></fa-icon>
                </a>
            </li>
            <li class="list-group-item flex-fill">
                <a class="nav-link">
                    <fa-icon [icon]="faShareAlt" class="fa-lg text-dark"></fa-icon>
                </a>
            </li>
        </ul>
        share | stats | like -->
    </div>
</div>
<!-- </div> -->