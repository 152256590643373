import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '@api/api/auth.service';
import { NowService } from '@api/api/now.service';
import { User } from '@api/model/models';

@Component({
  selector: 'app-now',
  templateUrl: './now.component.html',
  styleUrls: ['./now.component.scss']
})
export class NowComponent implements OnInit, AfterViewInit {

  user: User;

  constructor(
    private authService: AuthService
  ) { }

  async ngOnInit(): Promise<void> {
    this.user = await this.authService.currentUser().toPromise();
  }

  async ngAfterViewInit(): Promise<void> {
  }
}
