import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  private _apiUrl = "";
  private _uiUrl = "";

  constructor() { }

  initialise(): Promise<void> {
    return new Promise(resolve => {

      let hostname = window && window.location && window.location.hostname;

      if (/^.*localhost.*/.test(hostname)){
        this._apiUrl = "http://localhost:3000";
        this._uiUrl = "http://localhost:4200";
      }
      else if (/^preview.waterx.co.za/.test(hostname)){
        this._apiUrl = "https://api-preview.waterx.co.za";
        this._uiUrl = "https://preview.waterx.co.za";
      }
      else if (/^waterx.co.za/.test(hostname)){
        this._apiUrl = "https://api.waterx.co.za";
        this._uiUrl = "https://waterx.co.za";
      }
      resolve();
    });
  }

  get apiUrl() {
    return this._apiUrl;
  }

  get redirectUrl() {
    return this._uiUrl + "/callback"
  }

}
