import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {
  lat = -33.94801827978988
  lng = 18.422644288343488;

  constructor() { }

  ngOnInit(): void {
  }

}
