import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';
import { BASE_PATH } from '../api/variables';
import { AuthInterceptor } from './core/interceptors/http.auth.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from '@app/core/authentication/auth.guard';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import { MatChipsModule } from '@angular/material/chips';
import { AgmCoreModule } from '@agm/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FeedComponent } from '@components/feed/feed.component';
import { TrackingComponent } from '@components/tracking/tracking.component';
import { InsightsComponent } from '@components/insights/insights.component';
import { SettingsComponent } from '@components/settings/settings.component';
import { ExploreComponent } from '@components/explore/explore.component';
import { FeedFollowingComponent } from '@components/feed/following/following.component';
import { FeedSelfComponent } from '@components/feed/self/self.component';
import { DailyActivityComponent } from '@components/shared/daily-activity/daily-activity.component';
import { NowComponent } from './components/now/now.component';
import { NowSummaryComponent } from './components/now/now-summary/now-summary.component';
import { LoginComponent } from './components/login/login.component';
import { CallbackComponent } from './components/callback/callback.component';
import { ApiModule } from '@api/api.module';
import { Configuration, ConfigurationParameters } from '@api/configuration';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { FriendsComponent } from './components/friends/friends.component';
import { ExploreMapComponent } from './components/explore/map/map.component';
import { ExploreNearbyComponent } from './components/explore/nearby/nearby.component';
import { HomeComponent } from './components/home/home.component';
import { EnvironmentService } from '@services/environment.service';


export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    apiKeys: {},
    withCredentials: true
  }
  return new Configuration(params);
}

@NgModule({
  declarations: [
    AppComponent,
    FeedComponent,
    TrackingComponent,
    InsightsComponent,
    SettingsComponent,
    ExploreComponent,
    FeedFollowingComponent,
    FeedSelfComponent,
    DailyActivityComponent,
    NowComponent,
    NowSummaryComponent,
    LoginComponent,
    CallbackComponent,
    TimeAgoPipe,
    DurationPipe,
    FriendsComponent,
    ExploreMapComponent,
    ExploreNearbyComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    NgbModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    MatDividerModule,
    MatListModule,
    MatTabsModule,
    MatCardModule,
    MatPaginatorModule,
    MatDialogModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatChipsModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    }),
    MatProgressSpinnerModule,
    NgxPermissionsModule.forRoot(),
    ApiModule.forRoot(apiConfigFactory)
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (environmentService: EnvironmentService) => () => environmentService.initialise(),
      deps: [EnvironmentService],
      multi: true
    },
    {
      provide: BASE_PATH,
      useFactory: (environmentService: EnvironmentService) => environmentService.apiUrl,
      deps: [EnvironmentService]
    },
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
