import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { faChartLine, faShareAlt, faUserFriends  } from '@fortawesome/free-solid-svg-icons';
import { faBell, faShareSquare } from '@fortawesome/free-regular-svg-icons';
import * as dayjs from 'dayjs'

@Component({
  selector: 'daily-activity',
  templateUrl: './daily-activity.component.html',
  styleUrls: ['./daily-activity.component.scss']
})
export class DailyActivityComponent implements OnInit {
  faShareAlt = faShareAlt;
  faChartLine = faChartLine;
  faShareSquare = faUserFriends;
  dateStr: string = ''

  @Input()
  data: any;

  // @ViewChild('carousel', { static: true }) carousel: NgbCarousel;
  // images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);

  constructor() {
  }

  ngOnInit(): void {
    this.dateStr = dayjs(this.data.date).format('ddd, D MMM YYYY');
  }

  ngAfterViewInit(): void {
    // this.carousel.pause();
  }

}
