<mat-toolbar class="toolbar">
  <mat-toolbar-row>
    <button mat-icon-button (click)="sidenav.toggle()" onclick="this.blur()" [disabled]="!isLoggedIn">
      <mat-icon>menu</mat-icon>
    </button>
    <h1>{{ title}}</h1>
    <!-- <div>
        <a mat-button [routerLink]="'/settings'"> Settings </a>
    </div> -->
    <span class="toolbar-spacer"></span>
    <button mat-icon-button [matMenuTriggerFor]="userMenu2" [hidden]="!isLoggedIn">
      <img src="{{ userImage }}" class="avatar" />
    </button>
    <mat-menu #userMenu2="matMenu" [hidden]="!isLoggedIn">
      <div *ngIf="isLoggedIn">
        <button mat-menu-item>
          Logged in as&nbsp;<b>{{ userName }}</b>
        </button>
        <button mat-menu-item (click)="logOut()">Logout</button>
      </div>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>

<!-- <mat-drawer-container class="example-container" [hasBackdrop]="hasBackdrop.value">
  <mat-drawer #drawer [mode]="mode.value">I'm a drawer</mat-drawer>
  <mat-drawer-content>
    <mat-form-field>
      <mat-label>Sidenav mode</mat-label>
      <mat-select #mode value="side">
        <mat-option value="side">Side</mat-option>
        <mat-option value="over">Over</mat-option>
        <mat-option value="push">Push</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Has backdrop</mat-label>
      <mat-select #hasBackdrop>
        <mat-option>Unset</mat-option>
        <mat-option [value]="true">True</mat-option>
        <mat-option [value]="false">False</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-raised-button (click)="drawer.toggle()">Toggle drawer</button>
  </mat-drawer-content>
</mat-drawer-container> -->

<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <mat-nav-list>

      <div *ngxPermissionsOnly="['lines:read']">
        <a mat-list-item routerLink="/home" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }"
          (click)="sidenav.close()">
          <span>Home</span>
        </a>
      </div>

      <!-- <mat-divider></mat-divider> -->

      <mat-list>
        <!-- Data -->
        <h3 mat-subheader>Data Capture</h3>
        <mat-list-item *ngxPermissionsOnly="['samples:read']">
          <!-- <mat-icon mat-list-icon>folder</mat-icon> -->
          <a mat-list-item routerLink="/data/lines" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }" (click)="sidenav.close()">
            <span>Site Visits</span>
          </a>
        </mat-list-item>
        <mat-list-item *ngxPermissionsOnly="['samples:read']">
          <!-- <mat-icon mat-list-icon>folder</mat-icon> -->
          <a mat-list-item routerLink="/data/lines" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }" (click)="sidenav.close()">
            <span>Water Samples</span>
          </a>
        </mat-list-item>
        <mat-list-item *ngxPermissionsOnly="['samples:read']">
          <!-- <mat-icon mat-list-icon>folder</mat-icon> -->
          <a mat-list-item routerLink="/data/lines" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }" (click)="sidenav.close()">
            <span>Maintenance Logs</span>
          </a>
        </mat-list-item>

        <!-- Analysis -->
        <h3 mat-subheader>Analysis</h3>
        <mat-list-item *ngxPermissionsOnly="['samples:read']">
          <a mat-list-item routerLink="/data/lines" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }" (click)="sidenav.close()">
            <span>Sample Analysis</span>
          </a>
        </mat-list-item>

        <!-- Monitoring -->
        <!-- <h3 mat-subheader>Monitor</h3>
        <mat-list-item *ngxPermissionsOnly="['samples:read']">
          <a mat-list-item routerLink="/data/lines" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }" (click)="sidenav.close()">
            <span>Alerts</span>
          </a>
        </mat-list-item> -->


        <!-- <mat-divider></mat-divider> -->
        <!-- <h3 mat-subheader *ngxPermissionsOnly="['reports:read']">Reports</h3>
        <mat-list-item *ngxPermissionsOnly="['reports:read']">
          <a mat-list-item routerLink="/reports/active-lines" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: false }" (click)="sidenav.close()">
            <span>-</span>
          </a>
        </mat-list-item> -->

        <!-- <mat-divider></mat-divider> -->
        <h3 mat-subheader *ngxPermissionsOnly="['users:read']">Manage</h3>
        <mat-list-item *ngxPermissionsOnly="['users:read']">
          <!-- <mat-icon mat-list-icon>folder</mat-icon> -->
          <a mat-list-item routerLink="/manage/users" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }" (click)="sidenav.close()">
            <span>Organisations</span>
          </a>
        </mat-list-item>
        <mat-list-item *ngxPermissionsOnly="['users:read']">
          <!-- <mat-icon mat-list-icon>folder</mat-icon> -->
          <a mat-list-item routerLink="/manage/users" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }" (click)="sidenav.close()">
            <span>Sites</span>
          </a>
        </mat-list-item>
        <mat-list-item *ngxPermissionsOnly="['users:read']">
          <!-- <mat-icon mat-list-icon>folder</mat-icon> -->
          <a mat-list-item routerLink="/manage/users" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }" (click)="sidenav.close()">
            <span>Users</span>
          </a>
        </mat-list-item>

      </mat-list>

      <!-- <a mat-list-item (click)="sidenav.toggle()" href="" mat-list-item>Close</a> -->

    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div style="height: 92vh;">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>