import { Injectable } from '@angular/core';
import { AuthService } from '@api/api/auth.service'
import { BehaviorSubject, Subject } from 'rxjs';
import { User } from '@api/model/models';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class UserService {
  public currentUserSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  public currentUserPermissions: string[] = [];

  constructor(
    private authService: AuthService,
    private permissionsService: NgxPermissionsService
  ) {
  }

  public async getCurrentUser() {
    const currentUser = await this.authService.currentUser().toPromise();
    const userPermissions = await this.authService.getUserPermissions().toPromise();
    this.currentUserPermissions = userPermissions.map(p => `${p.resource}:${p.permission}`);

    this.permissionsService.loadPermissions(this.currentUserPermissions || []);
    this.currentUserSubject.next(currentUser);
  }

}
