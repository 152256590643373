import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { TitleService } from '@services/title.service';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss']
})
export class ExploreComponent implements OnInit {

  constructor(
    private titleService: TitleService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
  }

  public get currentRoute(): string {
    return this.router.url
  }

  ngAfterViewInit(): void {
    this.determineIfSubtitleShown();
  }

  @HostListener('window:scroll', ['$event'])
  windowScrolled(event: any) {
    this.determineIfSubtitleShown();
  }

  private determineIfSubtitleShown() {
    if (window.pageYOffset <= 26 && this.titleService.subtitleVisible) {
      this.titleService.toggleSubtitleOff();
    }
    else if (window.pageYOffset > 26 && !this.titleService.subtitleVisible) {
      this.titleService.toggleSubtitleOn();
    }
  }
}
