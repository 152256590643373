<div>

    <div class="p-2 d-flex justify-content-center">
        My Pets
    </div>

    <div class="container">
        <div class="row" *ngIf="!!user;else ownPetsLoading">
            <div class="col-md-8 col-lg-6" *ngFor="let petId of user.ownPets">
                <now-summary [petId]="petId"></now-summary>
            </div>
        </div>
        <ng-template #ownPetsLoading>
            <mat-spinner diameter="20" color="accent" class="d-flex m-auto"></mat-spinner>
        </ng-template>
    </div>

    <div class="p-2 d-flex justify-content-center">
        Following
    </div>

    <div class="container">
        <div class="row" *ngIf="!!user;else followingPetsLoading">
            <div class="col-md-8 col-lg-6" *ngFor="let petId of user.followingPets">
                <now-summary [petId]="petId"></now-summary>
            </div>
        </div>
        <ng-template #followingPetsLoading>
            <mat-spinner diameter="20" color="accent" class="d-flex m-auto"></mat-spinner>
        </ng-template>
    </div>

</div>