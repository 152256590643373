<div class="p-2 d-flex justify-content-center">
        Your feed is empty.<br>
        Feed items will appear as your pets log activities.
</div>

<div>
    <div class="container">
        <div class="row">
            <div class="col-sm" *ngFor="let feedItem of feedItems">
                <daily-activity [data]="feedItem"></daily-activity>
            </div>
        </div>
    </div>
</div>