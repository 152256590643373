import { Component, OnInit } from '@angular/core';

import { v4 as uuidv4 } from 'uuid';
import { environment } from '@env/environment';
import { EnvironmentService } from '@services/environment.service';

@Component({
  selector: 'app-login',
  template: ''
})
export class LoginComponent implements OnInit {

  constructor(private environmentService: EnvironmentService) {

  }

  ngOnInit() {
    // generate and store nonce (this is used to prevent replay attacks)
    const nonce = this.generateAndStoreNonce();

    // redirect to start OAuth2.0 flow
    const redirectUrl =
      `${this.environmentService.apiUrl}/auth/oauth-login?` +
      `nonce=${encodeURIComponent(String(nonce))}` +
      `&redirectUrl=${encodeURIComponent(String(this.environmentService.redirectUrl))}`
    window.location.href = redirectUrl;
  }

  private generateAndStoreNonce(): string {
    const nonce = uuidv4();
    localStorage.setItem('nonce', nonce);
    return nonce;
  }
}
