// auth.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '@api/api/auth.service';
import { UserService } from '@services/user.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const requiredPermissions = route.data.requiredPermissions || [];

    if (this.userService.currentUserSubject.value == null) {
      await this.userService.getCurrentUser();
    }

    if (this.userService.currentUserSubject.value == null) {
      this.router.navigate(['/login']);
      return false;
    }

    // verify that the user has all the required permissions
    const missingPermissions = (requiredPermissions as string[]).filter(p => !(this.userService.currentUserPermissions).includes(p))
    if (missingPermissions.length > 0) {
      console.warn(`Access denied to '${route.url}': The following permissions are missing: ${missingPermissions}`);
      return false;
    }
    return true;
  }
}
