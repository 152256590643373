import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-self',
  templateUrl: './self.component.html',
  styleUrls: ['./self.component.scss']
})
export class FeedSelfComponent implements OnInit {

  feedItems = [
    // {
    //   name: 'Dr Pepper',
    //   date: new Date(),
    //   movingTime: 67,
    //   sedentaryTime: 730,
    //   activeScore: 91
    // },
    // {
    //   name: 'Dr Pepper',
    //   date: new Date(new Date().getTime() - (3 * 24 * 60 * 60 * 1000)),
    //   movingTime: 37,
    //   sedentaryTime: 830,
    //   activeScore: 41
    // }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
