import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';

dayjs.extend(duration)

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(minutes: number, ...args: unknown[]): unknown {
    return dayjs.duration(minutes, 'minutes').format('H[h] mm[min]');
  }

}
