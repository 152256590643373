import { Component } from '@angular/core';
import {Location} from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MediaObserver } from '@angular/flex-layout';

import { faUserFriends, faRoute, faPaw, faUserCog, faCog, faAngleLeft, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faBell, faCompass } from '@fortawesome/free-regular-svg-icons';

import { TitleService } from './services/title.service';
import { UserService } from '@services/user.service';
import { AuthService } from '@api/api/auth.service'
import { User } from '@api/model/models';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-root',
  animations: [
    trigger('updateTitlePosition', [
      state('open', style({
        top: '19px'
      })),
      state('closed', style({
        top: '12px',
      })),
      transition('open => closed', [
        animate('0.2s')
      ]),
      transition('closed => open', [
        animate('0.2s')
      ]),
    ]),
    trigger('showHideSubtitle', [
      state('open', style({
        opacity: 0,
        top: '40px'
      })),
      state('closed', style({
        opacity: 1,
      })),
      transition('open => closed', [
        animate('0.2s')
      ]),
      transition('closed => open', [
        animate('0.2s')
      ]),
    ]),
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showSubtitle = false;
  faBell = faBell;
  faRoute = faRoute;
  faCompass = faCompass;
  faPaw = faPaw;
  faUserCog = faUserCog;
  faUserFriends = faUserFriends;
  faCog = faCog;
  faAngleLeft = faAngleLeft;
  faUsers = faUsers;

  isOpen = true;
  private currentUser: User | null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public titleService: TitleService,
    private userService: UserService,
    private authService: AuthService,
    private permissionsService: NgxPermissionsService,
    private media: MediaObserver,
    private location: Location
  ) {
    this.permissionsService.loadPermissions([]);
  }

  async ngOnInit() {
    this.initUpdateTitleAfterNavigation();
    this.initSubtitleListener();

    this.userService.currentUserSubject.subscribe(async (currentUser: User | null) => {
      this.currentUser = currentUser;
    });
    await this.userService.getCurrentUser();
  }

  public get currentRoute(): string {
    return this.router.url
  }

  private initUpdateTitleAfterNavigation() {
    // Change page title on navigation or language change, based on route data
    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    onNavigationEnd.pipe(
      map(() => {
        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    )
      .subscribe(event => {
        // set title
        if (event['title']) {
          this.titleService.setTitle(event['title']);
        }

        if (event['subtitle']) {
          this.titleService.setSubtitle(event['subtitle']);
        }
      });
  }

  private initSubtitleListener() {
    this.titleService.subtitleShownSubject.subscribe(show => {
      if (show != this.showSubtitle) {
        this.showSubtitle = show;
        this.isOpen = !this.isOpen;
      }
    })
  }

  get isLoggedIn(): boolean {
    return !!this.currentUser;
  }

  get userName(): string | null {
    return (this.currentUser || {}).name || 'N/A'
  }

  get userImage(): string | null {
    // TODO: Add avatar/profile-pic

    return "";
    // return (this.currentUser || {}).avatarUrl || 'N/A'
  }

  get isMobile(): boolean {
    return this.media.isActive('xs') || this.media.isActive('sm');
  }

  backClicked() {
    this.location.back();
  }

  logOut() {
    // #TODO
    this.router.navigate(['/login']);
  }

  get title(): string {
    // TODO
    return this.titleService.getTitle();
  }

}
