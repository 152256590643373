import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { AuthService } from '@api/api/auth.service';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(async (queryParams) => {
      await this.handleAuthenticateCallback(queryParams);

      // redirect to home page after login
      this.userService.currentUserSubject.subscribe(async () => {
        this.router.navigate(['/']);
      });

      await this.userService.getCurrentUser();
    });
  }

  private async handleAuthenticateCallback(queryParams: any) {
    const body = {
      code: queryParams.code,
      state: queryParams.state,
      redirect: window.location.origin + '/callback'
    }

    return new Promise<void>((resolve, reject) => {
      this.authService.retrieveBearerToken(body)
        .pipe(first())
        .subscribe(async res => {
          if (!this.validateNonce(res.nonce)) {
            const error = new Error('Invalid nonce.');
            console.error(error);
            reject(error);
          } else {
            resolve();
          }
        }, err => {
          reject(err);
        });
    });
  }

  private validateNonce(token: string): boolean {
    const nonce = localStorage.getItem('nonce');
    localStorage.removeItem('nonce');
    if (nonce === null || token !== nonce) {
      return false;
    }
    return true;
  }

}
