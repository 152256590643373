import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/core/authentication/auth.guard';
import { FeedComponent } from '@components/feed/feed.component';
import { TrackingComponent } from '@components/tracking/tracking.component';
import { InsightsComponent } from '@components/insights/insights.component';
import { FriendsComponent } from '@components/friends/friends.component';
import { ExploreComponent } from '@components/explore/explore.component';
import { ExploreMapComponent } from '@components/explore/map/map.component';
import { ExploreNearbyComponent } from '@components/explore/nearby/nearby.component';
import { SettingsComponent } from '@components/settings/settings.component';
import { FeedFollowingComponent } from '@components/feed/following/following.component';
import { FeedSelfComponent } from '@components/feed/self/self.component';
import { NowComponent } from '@components/now/now.component';
import { LoginComponent } from '@components/login/login.component';
import { CallbackComponent } from '@components/callback/callback.component';
import { HomeComponent } from '@components/home/home.component';


export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    component: HomeComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'feed',
    component: FeedComponent,
    data: {
      title: 'Feed',
      reuse: true, // Reuse FeedComponent instance when navigating between child views
    },
    children: [
      {
        path: '',
        redirectTo: '/feed/following',
        pathMatch: 'full',
      },
      {
        path: 'following',
        component: FeedFollowingComponent,
        data: { title: 'Feed', subtitle: 'Following' }
      },
      {
        path: 'self',
        component: FeedSelfComponent,
        data: { title: 'Feed', subtitle: 'My Feed' }
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'friends',
    component: FriendsComponent,
    data: { title: 'Friends', subtitle: 'My Friends' },
    canActivate: [AuthGuard]
  },
  {
    path: 'tracking',
    component: TrackingComponent,
    data: { title: 'Tracking' },
    canActivate: [AuthGuard]
  },
  {
    path: 'now',
    component: NowComponent,
    data: { title: 'Now' },
    canActivate: [AuthGuard]
  },
  {
    path: 'explore',
    component: ExploreComponent,
    data: {
      title: 'Explore',
      reuse: true, // Reuse FeedComponent instance when navigating between child views
    },
    children: [
      {
        path: '',
        redirectTo: '/explore/map',
        pathMatch: 'full',
      },
      {
        path: 'map',
        component: ExploreMapComponent,
        data: { title: 'Explore', subtitle: 'Explore Map' }
      },
      {
        path: 'nearby',
        component: ExploreNearbyComponent,
        data: { title: 'Explore', subtitle: 'Explore Nearby' }
      },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'insights',
    component: InsightsComponent,
    data: { title: 'Insights' },
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data: { title: 'Settings' },
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Log In' }
  },
  {
    path: 'callback',
    component: CallbackComponent,
    data: { title: 'Login Callback' }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
