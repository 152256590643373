import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  public subtitleShownSubject = new BehaviorSubject(false);
  private title = '';
  private subtitle = '';
  private _subtitleVisible = false;

  constructor(private browserTitle: Title) {
  }

  public getTitle(): string {
    return this.title;
  }

  public setTitle(newTitle: string) {
    this.title = newTitle
    this.browserTitle.setTitle(`WaterX - ${newTitle}`);
  }

  public getSubtitle(): string {
    return this.subtitle;
  }

  public setSubtitle(newSubtitle: string) {
    this.subtitle = newSubtitle;
  }

  public toggleSubtitleOn() {
    this._subtitleVisible = true
    this.subtitleShownSubject.next(this._subtitleVisible);
  }

  public toggleSubtitleOff() {
    this._subtitleVisible = false
    this.subtitleShownSubject.next(this._subtitleVisible);
  }

  public get subtitleVisible(): boolean {
    return this._subtitleVisible;
  }

}
