import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { faChartLine, faShareAlt, faUserFriends, faBatteryEmpty, faBatteryHalf } from '@fortawesome/free-solid-svg-icons';
import { faBell, faShareSquare } from '@fortawesome/free-regular-svg-icons';
import { AgmMap } from '@agm/core';
import { PetService } from '@api/api/pet.service';
import { NowService } from '@api/api/now.service';
import { GetPetNow } from '@api/model/getPetNow';
import { GetPet } from '@api/model/getPet';

@Component({
  selector: 'now-summary',
  templateUrl: './now-summary.component.html',
  styleUrls: ['./now-summary.component.scss']
})
export class NowSummaryComponent implements OnInit, AfterViewInit {
  faShareAlt = faShareAlt;
  faChartLine = faChartLine;
  faShareSquare = faUserFriends;
  faBatteryEmpty = faBatteryEmpty;
  faBatteryHalf = faBatteryHalf;

  loaded = false;
  pet: GetPet;
  summary: GetPetNow;

  @Input()
  petId: string;

  @ViewChild('map')
  private map: AgmMap;

  constructor(
    private router: Router,
    private petService: PetService,
    private nowService: NowService
  ) {
  }

  ngOnInit(): void {
  }

  async ngAfterViewInit(): Promise<void> {
    this.pet = await this.petService.getPet(this.petId).toPromise();
    this.summary = await this.nowService.getNowForPet(this.petId).toPromise();
    this.loaded = true;
  }

  async mapReady(event: any) {
    this.map.gestureHandling = 'none';
    // console.log('@@@', this.map);
  }

  async mapClick(event: any) {
    this.router.navigate(["/tracking"]);
  }

}
