<div class="card mb-2 shadow-sm border-0 rounded-0" *ngIf="!!loaded; else loading">
    <div class="card-body">

        <div class="d-flex flex-row flex-wrap mb-3">
            <div class="p-0 border-0">
                <img src="//placehold.it/50" alt="" class="rounded-circle">
            </div>
            <div class="px-2">
                <div class="h5">{{ pet.name }}</div>
                <div class="small font-weight-light">Last seen: {{summary.lastSeen | timeAgo}}</div>
                <div class="small font-weight-light"> Battery: {{summary.batteryStatus}}%
                </div>
            </div>
        </div>

        <!-- <div class="">Daily Activity</div> -->
        <!-- <h4 class="card-subtitle mb-2 text-muted">Daily Activity</h4> -->

        <div class="container mt-2">
            <div class="row ml-l">
                <div class="ml-1">
                    <div class="card-subtitle text-muted">Steps</div>
                    <h3 class="m-0">{{ summary.steps }}</h3>
                </div>
                <div class="ml-5">
                    <div class="card-subtitle text-muted">Active Minutes</div>
                    <h3 class="m-0" *ngIf="summary.activeMinutes > 0">{{ summary.activeMinutes | duration }}</h3>
                    <h3 class="m-0" *ngIf="summary.activeMinutes == 0">-</h3>
                </div>
            </div>
        </div>

        <div class="mt-2 card-subtitle text-muted">Last Position</div>
        <div class="mt-1" *ngIf="!!summary && !!summary.lastPosition">
            <agm-map #map [zoom]="12" [latitude]="summary.lastPosition.lat" [longitude]="summary.lastPosition.lon"
                [disableDefaultUI]="true" [disableDoubleClickZoom]="true" [mapDraggable]="false"
                [clickableIcons]="false" (mapReady)="mapReady($event)" (mapClick)="mapClick($event)">
                <agm-marker [latitude]="summary.lastPosition.lat" [longitude]="summary.lastPosition.lon">
                    <!-- <agm-info-window>Börk</agm-info-window> -->
                </agm-marker>
            </agm-map>
        </div>

    </div>
</div>
<ng-template #loading>
    <mat-spinner diameter="20" color="accent" class="d-flex m-auto"></mat-spinner>
</ng-template>